import React, { useState, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import styled from "styled-components";

const Loader = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Container>
      <ContentLoader 
        speed={1.5}
        width={width}
        height={780}
        viewBox={`0 0 ${width} 780`}
        backgroundColor="#f6f6f6"
        foregroundColor="#ededed"
        {...props}
      >
        <rect x="0" y="0" rx="15" ry="15" width={width} height="190" />
        <rect x="20" y="235" rx="7" ry="7" width="100" height="14" />
        <rect x="20" y="285" rx="7" ry="7" width={width-40} height="14" />
        <rect x="20" y="355" rx="7" ry="7" width="140" height="14" />
        <rect x="20" y="405" rx="7" ry="7" width={width-40} height="14" />
        <rect x="20" y="475" rx="7" ry="7" width="120" height="14" />
        <rect x="20" y="525" rx="7" ry="7" width={width-40} height="14" />
      </ContentLoader>
    </Container>
  )
}

const Container = styled.div`
  background-color: #fff;
  overflow: hidden;
`;

export default Loader