import React, { useEffect, Suspense } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { ErrorBoundary  } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import moment from "moment";
import "moment/locale/ko";
import "moment/locale/en-au";
import "moment/locale/ja";
import { callNative } from "./utils/callNative";
import useCommonStore from "./store/common/store";
import useHomepageStore from "./store/site/common";
import TestPage from "./pages/Test";
import GPTPage from "./pages/GPT";
import Capture from "./pages/Capture";
import Loader from "./components/common/Loader";
import BrainbosLoader from "./components/brainbos/home/Loader";
import CommunityLoader from "./components/community/home/Loader";
import Tracker from "./Tracker";
import ErrorFallback from './ErrorFallback';
import './App.css';

const SignupAuthHome = React.lazy(() => import('./pages/signup/auth/HomePage'));
const SignupRegister = React.lazy(() => import('./pages/signup/register/HomePage'));
const SignupAuthCallback = React.lazy(() => import('./pages/signup/sns/CallbackPage'));
const SignupFindResult = React.lazy(() => import('./pages/signup/findresult/HomePage'));
const SignupNewPassword = React.lazy(() => import('./pages/signup/newpassword/HomePage'));
const SignupAgreeInfo = React.lazy(() => import('./pages/signup/agree/HomePage'));
const SignupSNS = React.lazy(() => import('./pages/signup/sns/HomePage'));
const SignupMarketing = React.lazy(() => import('./pages/signup/marketing/HomePage'));
const OauthLogin= React.lazy(() => import('./pages/oauth/LoginPage'));
const OauthCallback= React.lazy(() => import('./pages/oauth/CallbackPage'));
const HwadapLogin = React.lazy(() => import('./pages/hwadap/LoginPage'));
const HwadapHome = React.lazy(() => import('./pages/hwadap/room/HomePage'));
const HwadapMedia = React.lazy(() => import('./pages/hwadap/MediaPage'));
const HwadapGroupHome = React.lazy(() => import('./pages/hwadap/group/HomePage'));
const HwadapSlide = React.lazy(() => import('./pages/hwadap/slide/HwadapSlidePage'));
const HwadooSlide = React.lazy(() => import('./pages/hwadap/slide/HwadooSlidePage'));
const HwadapAllHome = React.lazy(() => import('./pages/hwadap/all/HomePage'));
const HwadapPreview = React.lazy(() => import('./pages/hwadap/preview/HomePage'));
const SiteHome = React.lazy(() => import('./pages/site/home/HomePage'));
const SiteLogin = React.lazy(() => import('./pages/site/home/LoginPage'));
const SiteNoticeHome = React.lazy(() => import('./pages/site/notice/HomePage'));
const SiteNoticeDetail = React.lazy(() => import('./pages/site/notice/DetailPage'));
const SiteContactUsHome = React.lazy(() => import('./pages/site/contact/HomePage'));
const SiteContactUsWrite = React.lazy(() => import('./pages/site/contact/WritePage'));
const SiteHelpHome = React.lazy(() => import('./pages/site/help/HomePage'));
const SiteHelpDetail = React.lazy(() => import('./pages/site/help/DetailPage'));
const SitePolicyHome = React.lazy(() => import('./pages/site/policy/HomePage'));
const SiteGoogleTranslate = React.lazy(() => import('./pages/site/policy/GoogleTranslatePage'));
const CommunityLogin = React.lazy(() => import('./pages/community/home/Login.page'));
const CommunityIntro = React.lazy(() => import('./pages/community/home/Intro.page'));
const CommunityAlarm = React.lazy(() => import('./pages/community/etc/Alarm.page'));
const CommunityReport = React.lazy(() => import('./pages/community/etc/Report.page'));
const CommunitySearch = React.lazy(() => import('./pages/community/search/Search.page'));
const CommunityHome = React.lazy(() => import('./pages/community/home/Home.page'));
const CommunityDetail = React.lazy(() => import('./pages/community/detail/Home.page'));
const CommunityDetailSlide = React.lazy(() => import('./pages/community/detail/Slide.page'));
const CommunityProfile = React.lazy(() => import('./pages/community/profile/Home.page'));
const CommunityProfileThumbnail = React.lazy(() => import('./pages/community/profile/Thumbnail.page'));
const CommunityMypageHome = React.lazy(() => import('./pages/community/mypage/Home.page'));
const CommunityMypageEditProfile = React.lazy(() => import('./pages/community/mypage/EditProfile.page'));
const CommunityMypageEtiquette = React.lazy(() => import('./pages/community/mypage/Etiquette.page'));
const CommunityMypageAlarm = React.lazy(() => import('./pages/community/mypage/Alarm.page'));
const CommunityMypageFriends = React.lazy(() => import('./pages/community/mypage/Friends.page'));
const CommunityMypageFeeds = React.lazy(() => import('./pages/community/mypage/Feeds.page'));
const CommunityMypageLikeComments = React.lazy(() => import('./pages/community/mypage/LikeComments.page'));
const CommunityMypageMyComments = React.lazy(() => import('./pages/community/mypage/MyComments.page'));
const CommunityIntermediary = React.lazy(() => import('./pages/community/etc/Intermediary.page'));
const EnergyLogin = React.lazy(() => import('./pages/energy/home/Login.page'));
const EnergyHome = React.lazy(() => import('./pages/energy/home/Home.page'));
const EnergyRechargeHome = React.lazy(() => import('./pages/energy/recharge/Home.page'));
const EnergyRechargeResult = React.lazy(() => import('./pages/energy/recharge/Result.page'));
const EnergyRechargeHistory = React.lazy(() => import('./pages/energy/recharge/History.page'));
const EnergyBallHome = React.lazy(() => import('./pages/energy/ball/Home.page'));
const EnergyBallHistory = React.lazy(() => import('./pages/energy/ball/History.page'));
const EnergyGiveHome = React.lazy(() => import('./pages/energy/give/Home.page'));
const EnergyGiveDetail = React.lazy(() => import('./pages/energy/give/Detail.page'));
const EnergyGiveResult = React.lazy(() => import('./pages/energy/give/Result.page'));
const EnergyGiveApply = React.lazy(() => import('./pages/energy/give/Apply.page'));
const EnergyPreview = React.lazy(() => import('./pages/energy/preview/Preview.page'));
const EnergyReportHome = React.lazy(() => import('./pages/energy/report/Home.page'));
const EnergyAlarmHome = React.lazy(() => import('./pages/energy/alarm/Home.page'));
const EnergyAlarmDetail = React.lazy(() => import('./pages/energy/alarm/Detail.page'));
const EnergyAlarmSetting = React.lazy(() => import('./pages/energy/alarm/Setting.page'));
const BrainbosLogin = React.lazy(() => import('./pages/brainbos/home/Login.page'));
const BrainbosHome = React.lazy(() => import('./pages/brainbos/home/Home.page'));
const BrainbosWrite = React.lazy(() => import('./pages/brainbos/write/Home.page'));
const BrainbosStatusAll = React.lazy(() => import('./pages/brainbos/status/All.page'));
const BrainbosStatusCampainHome = React.lazy(() => import('./pages/brainbos/status/CampainHome.page'));
const BrainbosStatusCampainDetail = React.lazy(() => import('./pages/brainbos/status/CampainDetail.page'));
const BrainbosStatusGoal = React.lazy(() => import('./pages/brainbos/status/Goal.page'));
const BrainbosStatusRecord = React.lazy(() => import('./pages/brainbos/status/Record.page'));
const BrainbosMedia = React.lazy(() => import('./pages/brainbos/feed/Media.page'));
const BrainbosComments = React.lazy(() => import('./pages/brainbos/feed/Comment.page'));
const BrainbosFriend = React.lazy(() => import('./pages/brainbos/friend/Home.page'));
const BrainbosFAQ = React.lazy(() => import('./pages/brainbos/home/FAQ.page'));
const BrainbosReport = React.lazy(() => import('./pages/brainbos/report/Home.page'));
const Notice = React.lazy(() => import('./pages/notice/HomePage'));
const GangchunGuide = React.lazy(() => import('./pages/gangchun/GuidePage'));
const EventLogin = React.lazy(() => import('./pages/event/LoginPage'));
const EventApply = React.lazy(() => import('./pages/event/ApplyPage'));

function App() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const langcd = searchParams.get('langcd') ?? '';
  const nationCd = searchParams.get('nationcd') ?? '';
  const device = searchParams.get('device') ?? '';
  const devicelangcd = searchParams.get('devicelangcd') ?? '';
  const setLangCd = useCommonStore((state) => state.setLangCd);
  const setDevice = useCommonStore((state) => state.setDevice);
  const setDeviceLangCd = useCommonStore((state) => state.setDeviceLangCd);
  const setNationCd = useCommonStore((state) => state.setNationCd);
  const setIsPC = useCommonStore((state) => state.setIsPC);
  const homepageLangCd = useHomepageStore((state) => state.langCd);
  const setHomepageLangCd = useHomepageStore((state) => state.setLangCd);
  const setHomepageNationCd = useHomepageStore((state) => state.setNationCd);
  const setHomepageOsTypeCd = useHomepageStore((state) => state.setOsTypeCd);

  Tracker();
  
  /** Back Key - From Native */
  const onClickBackKey = () => {
    if (pathname === '/community/feed') {
      return 0;
    }
    if (window.history.length <= 1 || pathname === '/energy/home') {
      callNative('common/?cmd=webviewclose');
    } else {
      navigate(-1);
    }
  }
  window.onClickBackKey = onClickBackKey;
  
  useEffect(() => {
    const agent = navigator.userAgent.toLowerCase();
    const os = ["android", "iphone", "macintosh", "win"];
    
    setIsPC(navigator.userAgent.includes('Windows') || navigator.userAgent.includes('Macintosh'));

    if (langcd) {
      setLangCd(langcd);
      setHomepageLangCd(langcd);
      i18n.changeLanguage(langcd);
    } else {
      if (homepageLangCd === '') {
        const navigatorLangCd = navigator.language.substring(0, 2) ?? 'ko';
        i18n.changeLanguage(navigatorLangCd);
        setHomepageLangCd(navigatorLangCd);
      } else {
        i18n.changeLanguage(homepageLangCd);
      }
    }

    if (device) {
      setDevice(device);
    }

    if (devicelangcd) {
      setDeviceLangCd(devicelangcd);
    }

    if (nationCd) {
      setNationCd(nationCd);
      setHomepageNationCd(nationCd);
    }

    if (langcd === 'ja' || homepageLangCd === 'ja') {
      document.body.classList.add('jp');
    }

    let osTypeCd = 'aos';
    for (const i of os) {
      if (agent.includes(i)) {
        if (i === 'android') {
          osTypeCd = 'aos';
        } else if (i === 'iphone') {
          osTypeCd = 'ios';
        } else if (i === 'macintosh') {
          osTypeCd = 'mac';
        } else if (i === 'windows') {
          osTypeCd = 'win';
        } else {
          osTypeCd = i;
        }
        break;
      }
    }
    setHomepageOsTypeCd(osTypeCd);

    if (langcd === 'ko') {
      moment.locale("ko");
      moment.updateLocale('ko', {
        relativeTime: {
          future: '%s',
          past: '%s',
          s: '방금 전',
          ss: '방금 전',
          m: '1분 전',
          mm: '%d분 전',
          h: '1시간 전',
          hh: '%d시간 전',
          d: '어제',
        },
      });
    } else if (langcd === 'ja') {
      moment.locale("ja");
      moment.updateLocale('ja', {
        relativeTime: {
          future: '%s',
          past: '%s',
          s: 'たった今',
          ss: 'たった今',
          m: '1分前',
          mm: '%d分前',
          h: '1時間前',
          hh: '%d時間前',
          d: '昨日',
        },
      });
    } else {
      moment.locale("en-au");
      moment.updateLocale('en', {
        relativeTime: {
          future: '%s',
          past: '%s',
          s: 'Just now',
          ss: 'Just now',
          m: '1minute ago',
          mm: '%dmins ago',
          h: '1hours ago',
          hh: '%dhours ago',
          d: 'yesterday',
        },
      });
    }
  }, []);

  return (
    <>
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={(details) => {console.log(details)}}>
      <Helmet>
        {homepageLangCd === "ko"
          ? <title>Zero(지로)</title>
          : <title>Zero</title>
        }
      </Helmet>
      <Routes>
        {/*** 홈페이지 ***/}
        <Route path="/" element={<Suspense fallback={<Loader />}><SiteHome /></Suspense>} />
        <Route path="/customer/login" element={<Suspense fallback={<Loader />}><SiteLogin /></Suspense>} />
        <Route path="/customer/notice" element={<Suspense fallback={<Loader />}><SiteNoticeHome /></Suspense>} />
        <Route path="/customer/notice/:id" element={<Suspense fallback={<Loader />}><SiteNoticeDetail /></Suspense>} />
        <Route path="/customer/contact" element={<Suspense fallback={<Loader />}><SiteContactUsHome /></Suspense>} />
        <Route path="/customer/contact/form" element={<Suspense fallback={<Loader />}><SiteContactUsWrite /></Suspense>} />
        <Route path="/customer/help" element={<Suspense fallback={<Loader />}><SiteHelpHome /></Suspense>} />
        <Route path="/customer/help/:id" element={<Suspense fallback={<Loader />}><SiteHelpDetail /></Suspense>} />
        <Route path="/policy/:id" element={<Suspense fallback={<Loader />}><SitePolicyHome /></Suspense>} />
        <Route path="/policy/googletranslate" element={<Suspense fallback={<Loader />}><SiteGoogleTranslate /></Suspense>} />

        {/*** 회원가입 ***/}
        <Route path="/signup/auth/:type" element={<Suspense fallback={<Loader />}><SignupAuthHome /></Suspense>} />
        <Route path="/signup/register" element={<Suspense fallback={<Loader />}><SignupRegister /></Suspense>} />
        <Route path="/signup/authentication/callback" element={<Suspense fallback={<Loader />}><SignupAuthCallback /></Suspense>} />
        <Route path="/signup/findresult" element={<Suspense fallback={<Loader />}><SignupFindResult /></Suspense>} />
        <Route path="/signup/:type" element={<Suspense fallback={<Loader />}><SignupNewPassword /></Suspense>} />
        <Route path="/signup/agreeinfo" element={<Suspense fallback={<Loader />}><SignupAgreeInfo /></Suspense>} />
        <Route path="/signup/sns" element={<Suspense fallback={<Loader />}><SignupSNS /></Suspense>} />
        <Route path="/signup/policy/marketing" element={<Suspense fallback={<Loader />}><SignupMarketing /></Suspense>} />

        {/*** Oauth ***/}
        <Route path="/oauth/login/:id" element={<Suspense fallback={<Loader />}><OauthLogin /></Suspense>} />
        <Route path="/oauth/callback/:id" element={<Suspense fallback={<Loader />}><OauthCallback/></Suspense>} />
        
        {/*** 화답하기 ***/}
        <Route path="/hwadap/login" element={<Suspense fallback={<Loader />}><HwadapLogin /></Suspense>} />
        <Route path="/hwadap/room" element={<Suspense fallback={<Loader />}><HwadapHome /></Suspense>} />
        <Route path="/hwadap/slide/hwadoo" element={<Suspense fallback={<Loader />}><HwadooSlide /></Suspense>} />
        <Route path="/hwadap/media" element={<Suspense fallback={<Loader />}><HwadapMedia /></Suspense>} />
        <Route path="/hwadap/room/:id" element={<Suspense fallback={<Loader />}><HwadapGroupHome /></Suspense>} />
        <Route path="/hwadap/slide/hwadap" element={<Suspense fallback={<Loader />}><HwadapSlide /></Suspense>} />
        <Route path="/hwadap/all" element={<Suspense fallback={<Loader />}><HwadapAllHome /></Suspense>} />
        <Route path="/hwadap/preview/:id" element={<Suspense fallback={<Loader />}><HwadapPreview /></Suspense>} />

        {/*** 브레인보스 ***/}
        <Route path="/brainbos/login" element={<Suspense fallback={<BrainbosLoader />}><BrainbosLogin /></Suspense>} />
        <Route path="/brainbos/home" element={<Suspense fallback={<BrainbosLoader />}><BrainbosHome /></Suspense>} />
        <Route path="/brainbos/write" element={<Suspense fallback={null}><BrainbosWrite /></Suspense>} />
        <Route path="/brainbos/write/:date" element={<Suspense fallback={null}><BrainbosWrite /></Suspense>} />
        <Route path="/brainbos/status/all" element={<Suspense fallback={null}><BrainbosStatusAll /></Suspense>} />
        <Route path="/brainbos/status/detail" element={<Suspense fallback={null}><BrainbosStatusCampainHome /></Suspense>} />
        <Route path="/brainbos/status/detail/:id" element={<Suspense fallback={null}><BrainbosStatusCampainDetail /></Suspense>} />
        <Route path="/brainbos/status/goal" element={<Suspense fallback={null}><BrainbosStatusGoal /></Suspense>} />
        <Route path="/brainbos/status/record" element={<Suspense fallback={null}><BrainbosStatusRecord /></Suspense>} />
        <Route path="/brainbos/feed/media" element={<Suspense fallback={null}><BrainbosMedia /></Suspense>} />
        <Route path="/brainbos/feed/comment" element={<Suspense fallback={null}><BrainbosComments /></Suspense>} />
        <Route path="/brainbos/friend" element={<Suspense fallback={null}><BrainbosFriend /></Suspense>} />
        <Route path="/brainbos/faq" element={<Suspense fallback={null}><BrainbosFAQ /></Suspense>} />
        <Route path="/brainbos/report" element={<Suspense fallback={null}><BrainbosReport /></Suspense>} />

        {/*** 커뮤니티 ***/}
        <Route path="/community/login" element={<Suspense fallback={<CommunityLoader />}><CommunityLogin /></Suspense>} />
        <Route path="/community/intro" element={<Suspense fallback={null}><CommunityIntro /></Suspense>} />
        <Route path="/community/alarm" element={<Suspense fallback={null}><CommunityAlarm /></Suspense>} />
        <Route path="/community/report" element={<Suspense fallback={null}><CommunityReport /></Suspense>} />
        <Route path="/community/search" element={<Suspense fallback={null}><CommunitySearch /></Suspense>} />
        <Route path="/community/feed" element={<Suspense fallback={<CommunityLoader />}><CommunityHome /></Suspense>} />
        <Route path="/community/feed/:id" element={<Suspense fallback={null}><CommunityDetail /></Suspense>} />
        <Route path="/community/feed/slide" element={<Suspense fallback={null}><CommunityDetailSlide /></Suspense>} />
        <Route path="/community/profile" element={<Suspense fallback={null}><CommunityProfile /></Suspense>} />
        <Route path="/community/profile/thumbnail" element={<Suspense fallback={null}><CommunityProfileThumbnail /></Suspense>} />
        <Route path="/community/mypage" element={<Suspense fallback={null}><CommunityMypageHome /></Suspense>} />
        <Route path="/community/mypage/edit" element={<Suspense fallback={null}><CommunityMypageEditProfile /></Suspense>} />
        <Route path="/community/mypage/etiquette" element={<Suspense fallback={null}><CommunityMypageEtiquette /></Suspense>} />
        <Route path="/community/mypage/alarm" element={<Suspense fallback={null}><CommunityMypageAlarm /></Suspense>} />
        <Route path="/community/mypage/friends/:id" element={<Suspense fallback={null}><CommunityMypageFriends /></Suspense>} />
        <Route path="/community/mypage/friends/me" element={<Suspense fallback={null}><CommunityMypageFriends /></Suspense>} />
        <Route path="/community/mypage/friends/my" element={<Suspense fallback={null}><CommunityMypageFriends /></Suspense>} />
        <Route path="/community/mypage/friends/block" element={<Suspense fallback={null}><CommunityMypageFriends /></Suspense>} />
        <Route path="/community/mypage/friends/comment/:cmidx" element={<Suspense fallback={null}><CommunityMypageFriends /></Suspense>} />
        <Route path="/community/mypage/feeds/:id" element={<Suspense fallback={null}><CommunityMypageFeeds /></Suspense>} />
        <Route path="/community/mypage/likecomments" element={<Suspense fallback={null}><CommunityMypageLikeComments /></Suspense>} />
        <Route path="/community/mypage/mycomments" element={<Suspense fallback={null}><CommunityMypageMyComments /></Suspense>} />
        <Route path="/community/intermediary" element={<Suspense fallback={null}><CommunityIntermediary /></Suspense>} />

        {/*** 에너지 충전 ***/}
        <Route path="/energy/login" element={<Suspense fallback={<Loader />}><EnergyLogin /></Suspense>} />
        <Route path="/energy/home" element={<Suspense fallback={null}><EnergyHome /></Suspense>} />
        <Route path="/energy/recharge" element={<Suspense fallback={null}><EnergyRechargeHome /></Suspense>} />
        <Route path="/energy/recharge/result/:id" element={<Suspense fallback={null}><EnergyRechargeResult /></Suspense>} />
        <Route path="/energy/recharge/history" element={<Suspense fallback={null}><EnergyRechargeHistory /></Suspense>} />
        <Route path="/energy/ball" element={<Suspense fallback={null}><EnergyBallHome /></Suspense>} />
        <Route path="/energy/ball/history" element={<Suspense fallback={null}><EnergyBallHistory /></Suspense>} />
        <Route path="/energy/give/apply" element={<Suspense fallback={null}><EnergyGiveApply /></Suspense>} />
        <Route path="/energy/give/apply/:id" element={<Suspense fallback={null}><EnergyGiveApply /></Suspense>} />
        <Route path="/energy/give/:type" element={<Suspense fallback={null}><EnergyGiveHome /></Suspense>} />
        <Route path="/energy/give/:type/:id" element={<Suspense fallback={null}><EnergyGiveDetail /></Suspense>} />
        <Route path="/energy/give/:type/result/:id" element={<Suspense fallback={null}><EnergyGiveResult /></Suspense>} />
        <Route path="/energy/preview/:id" element={<Suspense fallback={null}><EnergyPreview /></Suspense>} />
        <Route path="/energy/report" element={<Suspense fallback={null}><EnergyReportHome /></Suspense>} />
        <Route path="/energy/alarm" element={<Suspense fallback={null}><EnergyAlarmHome /></Suspense>} />
        <Route path="/energy/alarm/:id" element={<Suspense fallback={null}><EnergyAlarmDetail /></Suspense>} />
        <Route path="/energy/alarm/setting" element={<Suspense fallback={null}><EnergyAlarmSetting /></Suspense>} />

        {/*** 팝업 안내 ***/}
        <Route path="/notice/:type" element={<Notice />} />

        {/*** 강천 이용안내 ***/}
        <Route path="/gangchun/guide" element={<GangchunGuide />} />

        {/*** 이벤트 ***/}
        <Route path="/event/login" element={<EventLogin />} />
        <Route path="/event/apply" element={<EventApply />} />

        {/*** 테스트 ***/}
        <Route path="/test" element={<TestPage />} />
        <Route path="/gpt" element={<GPTPage />} />
        <Route path="/capture" element={<Capture />} />
      </Routes>
    </ErrorBoundary>
    </>
  );
}

export default App;
