import { useState } from 'react';
import axios from "axios";
import Loading from '../components/common/Loader';
import styled from "styled-components";

const client = axios.create({
  baseURL: process.env.REACT_APP_NODE_API_URI,
});

const GPT = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [url, SetUrl] = useState('');
  const [result, setResult] = useState('');

  const onYoutubeToText = async () => {
    try {
      if (url === '') {
        return;
      }
      setIsLoading(true);
      
      const { data } = await client.get(`/gpt/text?video_url=${url}`);
      setResult(data.result.text);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <input type="text" value={url} placeholder="Youtube URL" onChange={(e) => SetUrl(e.target.value)} />
      <button type="button" disabled={isLoading} onClick={onYoutubeToText}>Text 추출하기</button>
      <div className="result">{result}</div>
      {isLoading && <Loading />}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
  button {
    margin-top: 4rem;
    padding: 1rem 2rem;
    font-size: 1.8rem;
    border: 1px solid #000;
    &:disabled {
      color: #ccc;
      border-color: #ccc;
    }
  }
  .result {
    margin-top: 3rem;
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
`;

export default GPT;