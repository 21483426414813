import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import { callNative } from "./utils/callNative";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Container>
      <h1>{t('네트워크 연결 상태가 좋지 않습니다. 잠시 후 다시 시도해 주세요.')}(CODE: 600)</h1>
      {!pathname.includes('/community/') &&
        <button className="close" onClick={() => callNative('common/?cmd=webviewclose')}>
          {t('닫기')}
        </button>
      }
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  h1 {
    max-width: 30rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: #777;
    text-align: center;
  }
  .retry {
    margin-top: 1.2rem;
    img {
      width: 5rem;
    }
  }
  .close {
    margin-top: 1.2rem;
    font-size: 1.6rem;
    height: 3.7rem;
    width: 20rem;
    color: white;
    background-color: #999;
    border-radius: 0.7rem;
  }
`;

export default ErrorFallback;