import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import useCommunityStore from "./store/community/store";

const Tracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const communityUser = useCommunityStore((state) => state.user);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname });
      if (communityUser.userID) {
        ReactGA.set({ communityUser: communityUser.userID });
      }
      ReactGA.send("pageview");
    }
  }, [initialized, location]);
};

export default Tracker;