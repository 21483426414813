export const callNative = (url, isBanner=false) => {
  try {
    if (isBanner) {
      window.location.href = url;
    } else {
      window.location.href = 'com.zerohsp://' + url;
    }
  } catch (e) {
    console.log(e);
  }
}