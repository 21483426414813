import { useState } from "react";
import client from '../lib/api/node';
import html2canvas from 'html2canvas';
import styled from "styled-components";

const captureDOMAndUpload = async (elementId) => {
  const element = document.getElementById(elementId);
  const canvas = await html2canvas(element);
  return canvas;
};

const canvasToBlob = async (canvas) => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error('Canvas to Blob 변환 실패'));
      }
    }, 'image/jpg');
  });
};

const handleFileUpload = async (file) => {
  if (file) {
    let formData = new FormData();
    formData.append("s3file", file);

    try {
      const config = {
        header: { 'content-type' : 'multipart/form-data' }
      }
      const { data } = await client.post('/file/s3/upload/energy', formData, config);
      return data;
    } catch (e) {
      console.log(e);
    }
  }
}

const Capture = () => {
  const [imageSrc, setImageSrc] = useState('');
  const [resultUrl, setResultUrl] = useState('');

  /** 이미지 선택 */
  const onFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      
      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };
      
      reader.readAsDataURL(file);
    }
  };

  /** 캡쳐하기 */
  const handleCaptureAndUpload = async (elementId) => {
    try {
      const canvas = await captureDOMAndUpload(elementId);
      const blob = await canvasToBlob(canvas);
      const result = await handleFileUpload(blob);
      setResultUrl(result.fileUrl);
    } catch (error) {
      console.error('Error capturing or uploading:', error);
    }
  };

  return (
    <Container>
      <div id="result">
        {resultUrl &&
          <img src={resultUrl} alt="" />
        }
      </div>
      {imageSrc &&
        <div className="popup">
          <div className="wrap" id="capture">
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam aspernatur consectetur hic error sunt id excepturi, magnam perferendis maiores explicabo aperiam sed recusandae temporibus est repellat officia sequi obcaecati voluptas eveniet autem vitae. Commodi quod laborum quae aut incidunt aperiam voluptatum qui officia hic, modi eos temporibus nesciunt ea quaerat vel sapiente ad voluptate. Qui repellat quia cupiditate. Nostrum et sapiente perferendis eveniet debitis nesciunt harum enim illum eaque quisquam laborum, voluptas eum maiores rerum! Asperiores magnam porro, numquam deserunt quae inventore error quasi harum rem quod totam illo iure sunt molestias necessitatibus repudiandae ratione explicabo voluptate quidem sapiente ipsam!</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam aspernatur consectetur hic error sunt id excepturi, magnam perferendis maiores explicabo aperiam sed recusandae temporibus est repellat officia sequi obcaecati voluptas eveniet autem vitae. Commodi quod laborum quae aut incidunt aperiam voluptatum qui officia hic, modi eos temporibus nesciunt ea quaerat vel sapiente ad voluptate. Qui repellat quia cupiditate. Nostrum et sapiente perferendis eveniet debitis nesciunt harum enim illum eaque quisquam laborum, voluptas eum maiores rerum! Asperiores magnam porro, numquam deserunt quae inventore error quasi harum rem quod totam illo iure sunt molestias necessitatibus repudiandae ratione explicabo voluptate quidem sapiente ipsam!</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam aspernatur consectetur hic error sunt id excepturi, magnam perferendis maiores explicabo aperiam sed recusandae temporibus est repellat officia sequi obcaecati voluptas eveniet autem vitae. Commodi quod laborum quae aut incidunt aperiam voluptatum qui officia hic, modi eos temporibus nesciunt ea quaerat vel sapiente ad voluptate. Qui repellat quia cupiditate. Nostrum et sapiente perferendis eveniet debitis nesciunt harum enim illum eaque quisquam laborum, voluptas eum maiores rerum! Asperiores magnam porro, numquam deserunt quae inventore error quasi harum rem quod totam illo iure sunt molestias necessitatibus repudiandae ratione explicabo voluptate quidem sapiente ipsam!</p>
            <img src={imageSrc} alt="Uploaded" style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
        </div>
      }
      <input type="file" onChange={onFileChange} accept="image/*" />
      <button type="button" className="capture" onClick={() => handleCaptureAndUpload("capture")}>캡쳐</button>
    </Container>
  )
}

const Container = styled.div`
  .popup {
    background-color: rgba(0,0,0,0.6);
    z-index: 9;
    .wrap {
      background-color: #fff;
      overflow-y: auto;
    }
  }
  .capture {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 1rem;
    font-size: 30px;
    color: #fff;
    background-color: #000;
    z-index: 10;
  }
`;

export default Capture;