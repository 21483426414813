import axios from "axios";

const client = axios.create({
  baseURL: process.env.REACT_APP_NODE_API_URI,
  // withCredentials: true,
});

client.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === 401) {
      // const res = await client.get('/exercise/token');
      // const { accessToken } = res.data.result;
      // error.config.headers['Authorization'] = `Bearer ${accessToken}`;
      // client.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
      // const response = await axios.request(error.config);
      // return response;
    }
    if (error.response?.status === 424) {
      // alert('로그아웃되었습니다. Native 웹뷰 닫기 호출합니다.');
    }
    return Promise.reject(error);
  }
);

export default client;
