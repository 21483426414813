import create from "zustand";
import { devtools, persist } from "zustand/middleware";

let state = (set) => ({
  langCd: '',
  nationCd: '',
  osTypeCd: '',
  setLangCd: (data) => set(() => ({ langCd: data })),
  setNationCd: (data) => set(() => ({ nationCd: data })),
  setOsTypeCd: (data) => set(() => ({ osTypeCd: data })),
});

state = persist(state, { name: 'zeroSiteCommon', getStorage: () => localStorage });

const useCommonStore = create(devtools(state));

export default useCommonStore;
