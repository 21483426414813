import React, { useState, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import styled from "styled-components";

const Loader = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Container>
      <ContentLoader 
        speed={1.5}
        width={width - 20}
        height={780}
        viewBox={`0 0 ${width} 820`}
        backgroundColor="#f6f6f6"
        foregroundColor="#ededed"
        {...props}
      >
        <rect x="0" y="0" rx="15" ry="15" width="71" height="30" /> 
        {/* <circle cx="210" cy="20" r="14" /> 
        <circle cx="250" cy="20" r="14" /> 
        <circle cx="289" cy="20" r="14" /> 
        <circle cx="326" cy="20" r="14" />  */}
        <rect x="0" y="63" rx="15" ry="15" width="80" height="30" /> 
        <rect x="86" y="63" rx="15" ry="15" width="81" height="30" /> 
        <rect x="174" y="63" rx="15" ry="15" width="80" height="30" /> 
        <rect x="259" y="63" rx="15" ry="15" width="80" height="30" /> 
        <rect x="344" y="63" rx="15" ry="15" width="80" height="30" /> 
        <rect x="0" y="130" rx="35" ry="35" width="96" height="96" /> 
        <rect x="105" y="130" rx="35" ry="35" width="95" height="95" /> 
        <rect x="209" y="130" rx="35" ry="35" width="95" height="95" /> 
        <rect x="313" y="130" rx="35" ry="35" width="95" height="95" /> 
        <rect x="0" y="270" rx="10" ry="10" width={width - 20} height="214" /> 
        <rect x="0" y="502" rx="5" ry="5" width="173" height="10" /> 
        <rect x="0" y="522" rx="5" ry="5" width="120" height="10" /> 
        <rect x="0" y="560" rx="10" ry="10" width={width - 20} height="214" />
      </ContentLoader>
    </Container>
  )
}

const Container = styled.div`
  padding: 2rem;
  overflow: hidden;
`;

export default Loader