import create from "zustand";
import { devtools, persist } from "zustand/middleware";

let state = (set) => ({
  user: '',
  setUser: (data) => set(() => ({
    user: data,
  })),

  /** 국가리스트 */
  nations: [],
  setNations: (data) => set(() => ({
    nations: data,
  })),

  /** 배너 */
  banners: '',
  setBanners: (data) => set(() => ({
    banners: data,
  })),

  /** 추천 태그 */
  tags: '',
  setTags: (data) => set(() => ({
    tags: data,
  })),

  /** 주간 베스트 */
  weekBest: '',
  setWeekBest: (data) => set(() => ({
    weekBest: data,
  })),

  /** 게시글 */
  feeds: '',
  setFeeds: (data) => set(() => ({
    feeds: data,
  })),

  /** 게시글 PageNumber */
  feedsPageNum: 1,
  setFeedsPageNum: (data) => set(() => ({
    feedsPageNum: data,
  })),

  /** 메인 스크롤값 */
  homeScrollY: false,
  setHomeScrollY: (data) => set(() => ({
    homeScrollY: data,
  })),

  /** 메인 새로고침 */
  isRefreshHome: false,
  setIsRefreshHome: (data) => set(() => ({
    isRefreshHome: data,
  })),

  /** 슬라이드 */
  slide: '',
  setSlide: (data) => set(() => ({
    slide: data
  })),

  /** 프로필 게시글 */
  profileFeeds: '',
  setProfileFeeds: (data) => set(() => ({
    feeds: data,
  })),

  /** 프로필 게시글 PageNumber */
  profileFeedsPageNum: 1,
  setProfileFeedsPageNum: (data) => set(() => ({
    profileFeedsPageNum: data,
  })),

  /** 프로필 ID */
  profileID: '',
  setProfileID: (data) => set(() => ({
    profileID: data,
  })),

  /** 프로필 썸네일 */
  profileThumbnail: '',
  setProfileThumbnail: (data) => set(() => ({
    profileThumbnail: data,
  })),

  /** 게시글 상세 댓글 시작 위치 이동 */
  scrollComment: false,
  setScrollComment: (data) => set(() => ({
    scrollComment: data,
  })),

  /** 알림 빨간점 */
  isNotify: false,
  setIsNotify: (data) => set(() => ({
    isNotify: data,
  })),

  /** 글로벌 설정 */
  global: '',
  setGlobal: (data) => set(() => ({
    global: data,
  })),

  /** 프로필 설정 완료 Toast */
  profileToast: false,
  setProfileToast: (data) => set(() => ({
    profileToast: data,
  })),

  /** 내 홈/ 친구 홈에서 글쓰기, 수정 완료 Toast */
  writeToast: false,
  setWriteToast: (data) => set(() => ({
    writeToast: data,
  })),

  /** 삭제 완료 Toast */
  deleteToast: false,
  setDeleteToast: (data) => set(() => ({
    deleteToast: data,
  })),

  /** 동영상 소리 설정 */
  isMuted: true,
  setIsMuted: (data) => set(() => ({
    isMuted: data,
  })),

  /** 동영상 재생 설정 (글쓰기 할 때 정지) */
  isCanPlay: true,
  setIsCanPlay: (data) => set(() => ({
    isCanPlay: data,
  })),

  /** 전달하기 버튼 이미지 경로 */
  relayBtnIconUrl: 'https://earthtalk-contents-test.s3.ap-northeast-2.amazonaws.com/earthTalkcontents/bizuser/202304/earthtalk1681457182876065.png',
  setRelayBtnIconUrl: (data) => set(() => ({
    relayBtnIconUrl: data,
  })),
  
  /** 더보기 닫기 */
  hideMoreButtons: 0,
  setHideMoreButtons: (data) => set(() => ({
    hideMoreButtons: data,
  })),
  
  /** 링크 이동 여부 */
  isLinkMoved: false,
  setIsLinkMoved: (data) => set(() => ({
    isLinkMoved: data,
  })),

  /** 팝업 */
  popup: {
    type: '',
    content: '',
    isOpen: '',
  },
  setPopup: (data) => set(() => ({
    popup: data,
  })),
  setClearPopup: (data) => set(() => ({
    popup: {
      type: '',
      content: '',
      isOpen: '',
    }
  })),
});

const useStore = create(devtools(state));

export default useStore;