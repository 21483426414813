import create from "zustand";
import { devtools, persist } from "zustand/middleware";

let state = (set) => ({
  langCd: '',
  device: '',
  deviceLangCd: '',
  nationCd: '',
  isPC: '',
  setLangCd: (data) => set(() => ({ langCd: data })),
  setDevice: (data) => set(() => ({ device: data })),
  setDeviceLangCd: (data) => set(() => ({ deviceLangCd: data })),
  setNationCd: (data) => set(() => ({ nationCd: data })),
  setIsPC: (data) => set(() => ({ isPC: data })),
});

const useCommonStore = create(devtools(state));

export default useCommonStore;